<template>
  <div class="guide-container">
    <h1>How to buy bitcoin on binance with debit card / credit card</h1>

    <h2 class="second-colour">
      Steps
    </h2>
    
    <p>
      1) Navigate to the Binance home page
      <!-- https://reactgo.com/vue-open-link-new-tab/ -->
      <a href="https://accounts.binance.com/en" target="_blank">here</a> and
      login. In the upper left click on 'Buy Crypto' and then 'Credit / Debit
      card'. You can change the currency you are buying in by clicking on the currency icon in the upper right of the menu. Note that changing currencies will change how you can purchase crypto.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyCryptoFromBankAccountDirectly/1.jpg"
      alt="Buy crypto via card menu drop down"
    />

    <p>2) Click 'Get it'.</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyCryptoFromBankAccountDirectly/2.jpg"
      alt="Buy crypto in 3 steps popup"
    />

    <p>
      3) Enter the amount you would like to pay, your payment
      currency, and the type of crypto currency you would like to purchase.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyCryptoFromBankAccountDirectly/3.jpg"
      alt="Buy crypto with card amount screen"
    />

    <p>
      4) Here you can see the minimum amount of 15 in GBP has been entered. And below
      is the amount of bitcoin that will be purchased. Click 'Continue'
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyCryptoFromBankAccountDirectly/4.jpg"
      alt="Buy crypto with card screen with example minimum amount of 15 gbp"
    />

    <p>5) Click on 'Add new card'</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyCryptoFromBankAccountDirectly/5.jpg"
      alt="Binance add new bank card page"
    />

    <p>6) Click on 'Add new card' again.</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyCryptoFromBankAccountDirectly/6.jpg"
      alt="Change card modal popup"
    />

    <p>7) Enter your name, card details, and click 'Confirm'.</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyCryptoFromBankAccountDirectly/7.jpg"
      alt="Add new card details - name, date, card number, post code popup"
    />

    <p>8) Click 'Confirm'.</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyCryptoFromBankAccountDirectly/9.jpg"
      alt="Change card confirmation popup"
    />

    <p>
      9) You'll see the amount you will spend, and the amount of crypto you are
      purchasing. Click 'Continue'.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyCryptoFromBankAccountDirectly/10.jpg"
      alt="Choose payment method continue screen"
    />

    <p>
      10) You will need to activate the fiat service and allow information to be
      shared. Click 'Start now'.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyCryptoFromBankAccountDirectly/11.jpg"
      alt="Upgrade your fiat service popup"
    />

    <p>
      11) Agree to the terms of use by clicking the checkbox. Then click 'Start'.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyCryptoFromBankAccountDirectly/12.jpg"
      alt="Binance activate fiat service popup"
    />

    <p>12) Agree to sharing your information. Then click 'Confirm'.</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyCryptoFromBankAccountDirectly/13.jpg"
      alt="Share account details terms and conditions popup"
    />

    <p>13) Your account should now be activated. Click 'Ok'.</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyCryptoFromBankAccountDirectly/14.jpg"
      alt="Fiat service activated popup"
    />

    <p>14) On the next screen you will see a summary of the amount you will spend including the transaction fee. You have a few seconds to agree since the price of the crypto coin changes moment to moment. Click the checkbox to agree to the terms and then click 'Confirm'.</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyCryptoFromBankAccountDirectly/15.jpg"
      alt="Confirm buy crypto screen with timer countdown"
    />

     <p>15) The purchase complete screen will appear. Click on 'Go to wallet'.</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyCryptoFromBankAccountDirectly/16.jpg"
      alt="Purchase complete screen with go to wallet button"
    />

    <p>16) You can also see your wallet page by clicking on 'wallet' in the upper right and then 'fiat and spot'. This page shows all cryptos and fiat currency you hold in your Binance account.</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyCryptoFromBankAccountDirectly/18.jpg"
      alt="Binance fiat and spot balance screen"
    />

    <p>17) You can also use the Binance mobile app to view your wallet. Open the app and click on the chart icon in the lower left.</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyCryptoFromBankAccountDirectly/19.jpg"
      alt="Binance Android app balance screen"
    />

    <p>Congratulations. You've now purchased some crypto currency through your Binance account.</p>
  </div>
</template>
